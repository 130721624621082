import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Flex } from "rebass";
import CloseIcon from "../../assets/icons/close.svg";
import ModalGlobalStyles from "./ModalGlobalStyles";

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #1e242fcc;
  z-index: 20;
`;

const Content = styled(Flex)`
  display: block;
  position: absolute;
  margin: auto;
  top: 0px;
  bottom: 0px;
`;

const Modal = ({ children, closeModal, isOpenModal }) => {
  return isOpenModal ? (
    <>
      <Wrapper onClick={closeModal}>
        <Box
          css={`
            position: absolute;
            right: 50px;
            top: 50px;
            transform: scale(1);
            transition-duration: 0.3s;
            transition-property: transform;
            cursor: pointer;
            z-index: 1;
            :hover {
              transform: scale(1.2);
            }
            @media (max-width: 767px) {
              right: 20px;
              top: 20px;
            }
          `}
        >
          <CloseIcon onClick={closeModal} />
        </Box>
        <Content width={["90%", "80%", "70%", "50%"]}>{children}</Content>
      </Wrapper>
      <ModalGlobalStyles modalIsOpen />
    </>
  ) : null;
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.shape(),
  isOpenModal: PropTypes.bool.isRequired
};

Modal.defaultProps = {
  children: null
};

export default Modal;
