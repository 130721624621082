import { useState } from "react";

// eslint-disable-next-line import/prefer-default-export
export const useScroll = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleChangePosition = ({ currentPosition, previousPosition }) => {
    if (currentPosition === "inside" && previousPosition === "below") {
      setIsVisible(true);
    }
    if (currentPosition === "below" && previousPosition === "inside") {
      setIsVisible(false);
    }
    if (
      (currentPosition === "above" && previousPosition === "below") ||
      currentPosition === "inside" ||
      (currentPosition === "above" && previousPosition === undefined)
    ) {
      setIsVisible(true);
    }
  };
  return [isVisible, handleChangePosition];
};
