import { createGlobalStyle } from "styled-components";

const ModalGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
  #___gatsby {
    width: ${({ modalIsOpen }) => (modalIsOpen ? "100%" : "unset")};
    ${({ modalIsOpen }) =>
      modalIsOpen
        ? `
      @media (max-width: 1023px) and (orientation: landscape) {
      position: unset; 
      max-height: 700px;
      overflow: hidden;
    }
      `
        : null};
  }
`;

export default ModalGlobalStyles;
