import { useState, useCallback } from "react";

// eslint-disable-next-line import/prefer-default-export
export const useModal = () => {
  const [isOpenModal, setOpenModal] = useState(false);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);
  const openModal = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);
  return [isOpenModal, openModal, closeModal];
};
