import * as React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 56.25%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  & > div {
    position: absolute;
  }
`;

const Player = ({ src, ...props }) => (
  <ReactPlayer url={src} {...props} wrapper={Wrapper} />
);

Player.propTypes = {
  src: PropTypes.string.isRequired
};

export default Player;
